import React from "react"

import { graphql, useStaticQuery } from "gatsby"

// Component imports
import Text from "./Text"
import Image from "gatsby-image"

// Static assets
import { ReactComponent as Instagram } from "../images/icons/instagram.svg"
import { ReactComponent as LinkedIn } from "../images/icons/linkedin.svg"
import { ReactComponent as Twitter } from "../images/icons/twitter.svg"
import { ReactComponent as Twitch } from "../images/icons/twitch.svg"
import { ReactComponent as YouTube } from "../images/icons/youtube.svg"

const Footer = () => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Components
  const SocialMedia = ({
    icon,
    name,
    url,
  }: {
    icon: any
    name: string
    url: string
  }) => {
    const Icon = icon
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="focus:outline-none focus:text-blue-500 hover:text-blue-500"
      >
        <span className="sr-only">{name}</span>
        <Icon className="w-6 h-6 transition duration-200 ease-out shadow-2xl" />
      </a>
    )
  }
  return (
    <div className="bg-gray-900">
      <footer className="md:px-12 lg:py-12 md:space-y-4 lg:space-y-0 lg:flex-row md:justify-between md:items-center container flex flex-col items-center justify-center px-8 py-6 mx-auto">
        {/* Logo */}
        <div className="lg:w-auto w-full">
          <Image
            className="w-40 mx-auto"
            fluid={logo.childImageSharp.fluid}
            alt="Streamline Group logo"
          />
        </div>

        {/* Copyright */}
        <Text className="lg:text-base md:order-2 md:mt-0 order-last block w-full mt-4 text-sm text-center">
          &copy; Streamline Group. All Rights Reserved
        </Text>

        {/* Socials*/}
        <ul className="lg:space-x-10 lg:w-auto md:order-last md:mt-0 flex items-center justify-center order-2 w-full mt-6 space-x-6">
          <li>
            <SocialMedia
              icon={Twitter}
              name="Twitter"
              url="https://twitter.com/group_str"
            />
          </li>
          <li>
            <SocialMedia
              icon={LinkedIn}
              name="LinkedIn"
              url="https://www.linkedin.com/company/group-streamline/"
            />
          </li>
          <li>
            <SocialMedia
              icon={Instagram}
              name="Instagram"
              url="https://www.instagram.com/streamline_group/"
            />
          </li>
          <li>
            <SocialMedia
              icon={YouTube}
              name="YouTube"
              url="https://www.youtube.com/channel/UC3hYmQX6BtRHOwKXzrlSkZw"
            />
          </li>
          <li>
            <SocialMedia
              icon={Twitch}
              name="Twitch"
              url="https://twitch.tv/streamlinemedia"
            />
          </li>
        </ul>
      </footer>
    </div>
  )
}

export default Footer
