import React from "react"
import {
  GatsbySeo,
  GatsbySeoProps,
  OpenGraphImages,
} from "gatsby-plugin-next-seo"

import { useStaticQuery, graphql } from "gatsby"

const SEO: React.FC<SEOType> = ({
  title,
  description,
  image = {
    url: "/images/thumbnail.png",
    alt: "Streamline media thumbnail",
  },
  ...props
}) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const site = data.site.siteMetadata

  return (
    <GatsbySeo
      title={title}
      description={description}
      canonical={`${site.siteUrl}/${
        title === "Home" ? "/" : title.split(" ").join("-").toLowerCase()
      }`}
      openGraph={{
        url: `${site.siteUrl}/${
          title === "Home" ? "/" : title.split(" ").join("-").toLowerCase()
        }`,
        description,
        title,
        images: [image],
      }}
      {...props}
    />
  )
}

/**
 * Types
 */

type SEOType = {
  /** Title of the page */
  title: string
  description?: string
  image?: OpenGraphImages
} & GatsbySeoProps

export default SEO
