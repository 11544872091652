import React from "react"

interface Props {
  tag?: string
  styleTo?: string
  children: any
  className?: string
}

const Text = ({ children, styleTo, tag = "p", className = "" }: Props) => {
  const textStyles: { [index: string]: any } = {
    h1:
      "text-4xl leading-relaxed md:text-5xl xl:text-6xl font-bold xl:leading-95px",
    h2: "text-xl leading-relaxed md:text-2xl xl:text-3xl xl:leading-45px",
    h3: "text-lg xl:text-2xl",
    h4: "",
    h5: "",
    h6: "",
    p: "text-lg leading-loose md:text-xl xl:leading-45px",
  }

  const index = !styleTo ? "p" : styleTo

  return React.createElement(
    tag,
    { className: `${textStyles[index]} ${className}` },
    children
  )
}

export default Text
