import React, { useState, useEffect } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

// Component imports
import { Link } from "gatsby"
import Image from "gatsby-image"

// CSS
import headerStyles from "../css/header.module.css"

// Interfaces
interface LinkInterface {
  url: string
  children: string
}

const Header = () => {
  const [mobileNav, toggleMobileNav] = useState(false)
  const [isScrolled, toggleIsScrolled] = useState(false)

  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 238, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Components
  const DesktopLink = ({ url, children }: LinkInterface) => (
    <Link
      to={url}
      className="text-base capitalize transition duration-200 ease-out focus:outline-none hover:text-blue-500 focus:text-blue-500"
    >
      {children}
    </Link>
  )

  const MobileLink = ({ url, children }: LinkInterface) => (
    <motion.div
      onClick={toggleMobileNavbar}
      variants={{
        open: {
          y: 0,
          opacity: 1,
          transition: {
            type: "tween",
            duration: 0.5,
            ease: "easeOut",
          },
        },
        close: {
          y: 40,
          opacity: 0,

          transition: {
            type: "tween",
            duration: 0.2,
            ease: "easeOut",
          },
        },
      }}
    >
      <Link
        to={url}
        onClick={toggleMobileNavbar}
        className={headerStyles.mobileLink}
      >
        {children}
      </Link>
    </motion.div>
  )
  const MobileNavbar = () => (
    <motion.div
      transition={{}}
      initial="close"
      exit="close"
      animate="open"
      variants={{
        open: {
          left: 0,
          transition: {
            type: "tween",
            duration: 0.5,
            ease: "easeOut",
            when: "beforeChildren",
            staggerChildren: 0.1,
          },
        },
        close: {
          left: "-100vw",
          transition: {
            type: "tween",
            duration: 0.5,
            ease: "easeOut",
            when: "afterChildren",
            staggerChildren: 0.075,
          },
        },
      }}
      className="fixed top-0 left-0 w-full h-screen bg-blue-500"
    >
      <ul className="container flex flex-col items-start justify-center h-full px-8 mx-auto">
        <li className="my-3 overflow-hidden">
          <MobileLink url="/">Home</MobileLink>
        </li>
        <li className="my-3 overflow-hidden">
          <MobileLink url="/about-us">About Us</MobileLink>
        </li>
        <li className="my-3 overflow-hidden">
          <MobileLink url="/contact">Contact</MobileLink>
        </li>
      </ul>
    </motion.div>
  )

  // Mobile navbar toggle helper
  const toggleMobileNavbar = () => {
    toggleMobileNav(!mobileNav)
  }

  // Set body overflow helper
  useEffect(() => {
    if (mobileNav) {
      document.querySelector("body")?.classList.add("overflow-hidden")
    } else {
      document.querySelector("body")?.classList.remove("overflow-hidden")
    }
  })

  // Set navbar background when page is scrolled
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY !== 0 ? toggleIsScrolled(true) : toggleIsScrolled(false)
    })
  })

  return (
    <header
      className={`fixed transition-all duration-200 ease-out delay-100 top-0 left-0 z-50 flex items-center w-full h-16 lg:h-24 ${
        isScrolled ? "bg-gray-900" : ""
      }`}
    >
      <nav className="container flex items-center justify-between w-full px-8 mx-auto md:px-12">
        {/* Logo */}
        <Link
          to="/"
          onClick={() => {
            if (mobileNav) toggleMobileNavbar()
          }}
          className="relative z-50 block"
        >
          <span className="sr-only">Home</span>
          <Image
            className="w-40"
            fluid={logo.childImageSharp.fluid}
            alt="Streamline Media logo"
          />
        </Link>

        {/* Mobile Hamburger Button */}
        <motion.button
          onClick={toggleMobileNavbar}
          animate={mobileNav ? "open" : "close"}
          className="relative z-50 block w-8 h-8 select-none lg:hidden focus:outline-none"
        >
          <span className="sr-only">Menu</span>
          <motion.span
            transition={{
              ease: "easeOut",
              type: "tween",
              duration: 0.2,
            }}
            variants={{
              open: {
                rotate: 45,
                y: 8,
              },
              close: {
                rotate: 0,
                y: 0,
              },
            }}
            className="block w-8 h-1 bg-white rounded-lg"
          >
            &nbsp;
          </motion.span>
          <motion.span
            transition={{
              ease: "easeOut",
              type: "tween",
              duration: 0.1,
            }}
            variants={{
              open: {
                translateX: 25,
                opacity: 0,
              },
              close: {
                opacity: 1,
                translateX: 0,
              },
            }}
            className="block w-8 h-1 my-1 bg-white rounded-lg"
          >
            &nbsp;
          </motion.span>
          <motion.span
            transition={{
              ease: "easeOut",
              type: "tween",
              duration: 0.2,
            }}
            variants={{
              open: {
                rotate: -45,
                y: -8,
              },
              close: {
                rotate: 0,
                y: 0,
              },
            }}
            className="block w-8 h-1 bg-white rounded-lg"
          >
            &nbsp;
          </motion.span>
        </motion.button>

        {/* Mobile Navbar */}
        <AnimatePresence>{mobileNav && <MobileNavbar />}</AnimatePresence>

        {/* Desktop Navbar */}
        <ul className="hidden lg:flex lg:items-center">
          <li className="mr-12 xl:mr-24">
            <DesktopLink url="/">Home</DesktopLink>
          </li>
          <li>
            <DesktopLink url="/about-us">About Us</DesktopLink>
          </li>
          <li className="ml-16 xl:ml-32">
            <DesktopLink url="/contact">Contact</DesktopLink>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
