import React from "react"

/**
 * Component imports
 */
import { Link as GatsbyLink } from "gatsby"

/**
 * CSS imports
 */

//  @ts-ignore
import buttonStyles from "../css/button.module.css"

export const ExternalLink: React.FC<LinkProps> = ({
  variant = "primary",
  className = "",
  children,
  ...props
}) => {
  if (variant === "primary")
    return (
      <a
        className={`${buttonStyles.externalPrimary} ${className}`}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    )

  // Appease the TS Lords
  return null
}

export const Link: React.FC<LinkProps> = ({
  variant = "primary",
  className = "",
  children,
  ...props
}) => {
  if (variant === "primary") {
    return (
      <GatsbyLink
        to={props.url}
        className={`${buttonStyles.button} ${buttonStyles.primary} ${className}`}
        {...props}
      >
        {children}
      </GatsbyLink>
    )
  }

  // Appease the TS Lords
  return null
}

export const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  className = "",
  children,
  ...props
}) => {
  if (variant === "primary")
    return (
      <button
        className={`${buttonStyles.button} ${buttonStyles.primary} ${className}`}
        {...props}
      >
        {children}
      </button>
    )

  // Appease the TS Lords
  return null
}

/**
 * Types
 */

type ButtonProps = {
  /** The variant type for this button, used to define different styles. */
  variant?: string

  //** Use ONLY margins to add space around the buttons when other buttons are present inside the container! */
  className?: string
}

type LinkProps = ButtonProps & {
  /** Internal URL, remember to always begin with a '/'! */
  url: string
}
